@import '../../../../style/variables';

.reseller-upload-zone-container {
  height: 100%;
  min-height: 100px;
  position: relative;

  .drag-overlay {
    width: 100%;
    height: 100%;
    background-color: #eee;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s linear;
    visibility: hidden;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px dashed #aaa;
    }
  }
  .drag-overlay.active {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  .files-container {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .files-list,
    .file-chooser-container {
      transition: all 0.3s ease;
    }

    .files-list {
      flex-grow: 4;
      margin-right: 20px;
      max-width: 55%;
    }
    .file-chooser-container {
      flex-grow: 1;
      min-width: 40%;
    }

    &.chooser-extended {
      .files-list {
        flex-grow: 0;
        margin: 0;
      }
      .file-chooser-container {
        flex-grow: 1;
      }
    }
  }

  .file-chooser {
    background-color: #e9e9e9;
    border: 2px dashed #9d9d9d;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .flex-content {
      div {
        text-align: center;

        .material-icons {
          display: block;
          color: #9d9d9d;
          font-size: 80px;
        }
        div {
          color: #333;
          font-size: 18px;
        }

        .sub-title {
          font-size: 12px;
          color: #9d9d9d;

          p {
            margin: 0;
          }
        }
      }
    }

    .upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: block;
      text-align: center;
      input[type='file'] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }

    .restrictions {
      font-size: 13px;
      font-style: italic;
      text-align: center;
    }
  }
}

.rejected-file {
  .refusal-reason {
    font-weight: bold;
    color: #ff4444;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .reseller-upload-zone-container {
    .files-container {
      flex-direction: column-reverse;

      .files-list,
      .file-chooser-container {
        min-width: 65%;
        max-width: 100%;
        margin: 0;
      }

      .files-list {
        .inline-confirmation-button-container.visible-options {
          position: absolute;
        }
      }

      &.chooser-extended {
        .files-list {
          min-width: 0;
        }
        .file-chooser-container {
          max-width: 100% !important;
        }
      }

      .file-chooser-container {
        min-width: 30%;

        .file-chooser {
          margin-bottom: 5px;

          .material-icons {
            display: none;
          }

          .sub-title {
            padding-top: 5px;
            br {
              display: none;
            }
          }
        }
      }
      .upload-btn-wrapper button {
        padding: 10px 15px;
      }
    }
  }
}
