@import '../../../style/variables';

.quote-validation-page {
  > .row:first-child {
    margin-left: 0;
    margin-right: 0;
    padding-top: 30px;
  }

  .select-container {
    button {
      margin-left: 20px;
    }
  }

  .address-form-container {
    .md-form {
      width: 80%;
      margin: auto;
    }
    .address-info-sup {
      .form-group {
        margin-bottom: 0;
      }
    }

    .form-row {
      &.address-info-sup {
        .md-form {
          width: 90%;
        }
      }
    }
  }
  .cart-container {
    .cart-content {
      table {
        tr {
          th:first-child,
          td:first-child {
            width: 10% !important;
          }
          th:nth-child(2),
          td:nth-child(2) {
            width: 60% !important;
          }
        }
      }
    }
    .cart-height {
      min-height: 677px;
    }
  }
}
