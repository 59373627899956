@import '../../../../../style/variables';

.list {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #ddd;
}

.search-box {
  position: relative;

  :global {
    .form-row {
      margin-right: 0;
      margin-left: 0;

      .form-group {
        padding: 0;
      }

      .form-group,
      .md-form {
        margin: 0 !important;
      }
    }

    .fa {
      position: absolute;
      left: 15px;
      top: 12px;
      cursor: pointer;
      color: $primary-color-darker;
    }

    .form-row input {
      padding-left: 35px;
      padding-top: 10px;
      box-sizing: border-box;
    }

    .input-clear-button {
      top: 10px;
    }
  }
}

.item {
  background-color: #fff;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.4s;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}

.selected {
  font-weight: bold;
  background-color: #eee;
}

.disabled {
  cursor: default;
}

.disabled-text {
  opacity: 0.5;
}
