.delete-confirmation {
  text-align: center;
  height: 54px;
  padding-top: 1rem;
  padding-bottom: 0.9rem;
  background-color: rgba(100, 100, 100, 0.1);

  button {
    margin-top: 0;
    margin-bottom: 0;
  }

  button:first-child {
    margin-left: 20px;
  }
}
