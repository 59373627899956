@import '../../../style/variables';
.orders-list-container {
  min-height: 620px;
  margin-bottom: 40px;
  .order-list-title {
    background-color: #43b3be;
    color: white;
  }
  .result-container {
    table {
      td {
        font-weight: 400;
      }
    }
  }
}
.invalid-order {
  background: #f8d7da !important;
  border: solid 1px #f5c6cb;
}

.quotes-list-container {
  .quotes-list {
    min-height: 620px;
  }
}

.orders-list-container,
.quotes-list-container {
  .hover-surface-container {
    display: inline-block;
    .tooltip-dark {
      white-space: nowrap;
    }
  }
}

.invoices-list {
  min-height: 620px;
}
@media screen and (max-width: 1600px) {
  .user-container {
    margin-bottom: 20px;
  }
  .orders-list-container {
    .result-container {
      table {
        .order-actions-buttons {
          width: 9%;
        }
        .order-list-delivery {
          width: 18%;
        }
        .order-list-price {
          width: 10%;
        }
        .order-list-creation {
          width: 16%;
        }
      }
    }
  }
}
