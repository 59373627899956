.file-upload-item-container {
  margin: 10px 0;

  .card-header {
    background-color: #dedede;
    margin-bottom: 10px;

    a:hover {
      text-decoration: none;
    }

    h5 {
      color: black;
      font-family: Roboto;
      font-weight: bold;
      font-size: 15px;
      text-transform: uppercase;
      position: relative;

      i {
        position: absolute;
        right: 0px;
        font-size: 24px;
        top: -2px;
      }
    }
  }

  .order-item-title {
    display: flex;
    > .text {
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
    }

    .hover-surface-container {
      margin-right: 5px;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .custom-card {
    .content {
      padding: 15px 0 !important;
    }
  }
  .card.custom-card.file-upload-item-informations {
    margin-bottom: 0;
    height: 100%;
  }

  .file-not-required-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    color: #999;
    font-style: oblique;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .file-upload-item-container {
    .upload-zone-column {
      flex: 0 0 60%;
      max-width: 60%;
    }

    .product-info-column {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}
