$roboto-font-path: '/fonts/roboto/';

$primary-color: #00838f;
$primary-color-lighter: #4fb3bf;
$primary-color-darker: #005762;

$secondary-color: #ad1457;
$secondary-color-lighter: #cb396f;
$secondary-color-darker: #560027;

$text-color: #212121;

$danger-color: #ff4444;
$danger-color-dark: #cc0000;
$warning-color: #ffbb33;
$warning-color-dark: #ff8c00;
$success-color: #00c851;
$success-color-dark: #007e33;
$info-color: #33b5e5;
$info-color-dark: #0099cc;

$danger-color-bg: #ffd9d9;

$disabled-text-color: #aaa;
