@import '../../style/variables';

$button-width: 20px;
$button-height: 20px;

.tooltip-container {
  margin-left: 5px;
  margin-right: 2px;
  height: $button-width;
  width: $button-height;

  > span {
    display: inline-block;
    width: $button-width;
    height: $button-height;
  }
}

.button {
  cursor: pointer;
  border-radius: 50%;
  width: $button-width;
  height: $button-height;
  display: inline-block;
  user-select: none;

  &.active {
    color: $secondary-color;
  }

  :global  {
    .material-icons {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

.window {
  position: fixed;
  width: 500px;
  height: 300px;
  bottom: 30px;
  right: 20px;
  z-index: 10;
  background-color: #fff;
  color: #333;

  // Wrapper interne
  > div {
    position: relative;
    height: 100%;
  }
}

.title {
  font-size: 16px;
  line-height: 21px;
  background-color: $primary-color-darker;
  text-align: center;
  width: 100%;
  padding: 6px 0;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;

  .close-button {
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
  }
}

.content {
  text-align: left;
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 20px;
  padding-top: 10px;

  p {
    padding: 5px 0;
  }

  h1 {
    font-size: 20px;
    margin: 10px 0;
    color: $primary-color;
    font-weight: 500;

    &:not(:first-of-type) {
      border-top: 1px solid #ccc;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
  h2 {
    font-weight: normal;
    font-size: 18px;
    margin-top: 0.5rem;
  }
  h3 {
    font-size: 16px;
    margin-top: 0.5rem;
    font-weight: 400;
  }
  h4,
  h5,
  h6 {
    font-size: 15px;
  }
}
