.reseller-app {
  .nav-link:focus,
  .nav-link:hover {
    text-decoration: none;
  }

  // Couleurs

  .warning-text {
    color: $warning-color-dark;
  }
}
