@import '../variables';

.reseller-app {
  // Pas de padding latéral des cards
  .card.custom-card.wrapping-content > div > .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .card.custom-card {
    border: 0;

    .header {
      background-color: $primary-color-darker;
      color: white;
      border: 0;
      text-transform: uppercase;
      font-size: 20px;
    }
    .card {
      .header {
        font-size: 18px;
      }
    }
  }

  .card {
    border-radius: 0px;

    &.height100 {
      height: 100%;
    }

    .custom-badge {
      background-color: white;
      color: $secondary-color-darker;
      font-weight: bold;
      border-radius: 30% / 40%;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      margin-bottom: 10px;
      position: relative;
      top: 0;

      &.badge-with-left-margin {
        margin-left: 10px;
      }
    }

    &.bg-primary-darker-card-form .header {
      background-color: $primary-color-darker;
    }

    &.bg-primary-card-form .header {
      background-color: $primary-color;
    }

    &.bg-primary-lighter-card-form .header {
      background-color: $primary-color-lighter;
    }

    &.fixed-height-265px {
      height: 265px;
    }

    &.with-delivery-tracking {
      height: 285px;
    }

    &.bg-primary-darker-card-form .content,
    &.bg-primary-lighter-card-form .content,
    &.bg-primary-card-form .content {
      font-size: 14px;
      background-color: #eaeaea;
    }

    .sub-header {
      background-color: #dedede;
      font-weight: bold;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1600px) {
    .card.custom-card {
      .card {
        .header {
          font-size: 17px;
        }
        .sub-header {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
