@import '../../../style/variables';

.user-container {
  margin-top: 90px;

  .main-card {
    .card.custom-card {
      &.user-informations-container,
      &.user-address-container {
        margin-top: 20px;
      }
      &.user-address-container {
        .content {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .form-group {
      margin: 20px 0 120px 0;
    }
  }

  .Select-control {
    background-color: transparent;
  }
  &.user-infos-container {
    .user-infos {
      min-height: 620px;
    }
    .loader {
      margin-top: 15%;
    }
  }
  .form-row {
    margin-bottom: 110px;
    margin-top: 15px;

    input,
    div {
      margin-bottom: 0;
    }
  }
  .list-filters-container {
    background: #dfdfdf;
    padding: 15px 0 20px;
  }
}

@media screen and (max-width: 1600px) {
  .user-container {
    margin-top: 75px;
  }
}
