.container {
  display: block;
  position: absolute;
}

.tooltip-content {
  white-space: normal;
  font-weight: normal;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #212529;
  text-transform: none;
}
