@import '../style/variables';

.inline-confirmation-button-container {
  top: 0;
  right: 0;
  height: 24px;

  &.visible-options {
    background-color: $primary-color;
    top: 0;
    left: auto;
    height: 30px;
    width: 140px;
    padding: 3px;
    border-radius: 21px;

    .option-button__yes,
    .option-button__no {
      display: inline-block;
      margin: 0;
      padding: 4px;
      box-shadow: none;
      font-weight: 700;
      position: absolute;
    }

    .option-button__yes {
      right: 10px;
      float: right;
      color: $primary-color;
    }
    .option-button__no {
      left: 7px;
    }
  }

  .inner-confirmation-button-container {
    i {
      color: $primary-color;
      &:hover {
        color: $secondary-color-darker;
      }
    }

    .btn-link {
      position: relative;
      top: -20px;
      left: 35px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .inline-confirmation-button-container {
    &.visible-options {
      width: 150px;
    }
  }
}
