.hover-surface-container {
  position: relative;

  .default-surface {
    cursor: help;
  }

  .hovered-content {
    position: absolute;
    transition: opacity 0.25s ease-out;
    opacity: 0;
    z-index: 11; // Le header est à 10

    &.fully-displayed {
      opacity: 1;
    }

    &.fading-in {
      opacity: 1;
    }

    &.fading-out {
      opacity: 0;
    }
  }
}
