.upload-button-container {
  cursor: pointer;
  display: inline-block;
  position: relative;

  input[type='file'] {
    width: 0;
    height: 0;
    left: 0; // Pour ne pas avoir le cas du clic direct sur l'input
    position: absolute;
    outline: none;
  }
}
