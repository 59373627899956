@import '../style/variables';

.cancel-edit-button {
  display: inline-block;
  position: absolute;
  width: 90%;
  left: 5%;
  z-index: 9;
  background: $primary-color;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  top: 13px;
}

@media screen and (max-width: 1600px) {
  .cancel-edit-button {
    width: 165px;
  }
}
