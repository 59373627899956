@import '../../../../style/variables';

.quote-container {
  margin-top: 42px;

  &.breadcrumb-padded {
    margin-top: 142px;
  }
  .cart-container {
    margin-bottom: 50px;
    .cart-height {
      min-height: 671px;
    }

    .fixed-height-265px {
      margin-bottom: 95px;
    }

    .card-action-footer {
      position: absolute;
      bottom: 0;
    }
    .container.inner-cards {
      position: absolute;
      bottom: 0;
    }
    table {
      margin-bottom: 380px;
    }
  }
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .quote-container {
    &.breadcrumb-padded {
      margin-top: 130px;
      margin-bottom: 30px;
    }
    .cart-container {
      margin-bottom: 0;
      .cart-height {
        min-height: 668px;
      }
    }
  }
}
