.reseller-app {
  .filter-container {
    background-color: #dedede;
    padding: 4px;
  }

  .col-md-8.configurator {
    padding-right: 0;
  }

  .col-md-4.informations {
    padding-left: 0;

    .calculator-product-informations {
      padding-bottom: 77px;
    }
  }

  .calculator-container {
    padding: 1rem 30px;

    .form-row {
      .form-group {
        margin-bottom: 1.5rem;

        input.form-control.validate {
          margin-bottom: 0;
          border-color: rgba(0, 0, 0, 0.42);
        }

        &.amalgame {
          label {
            white-space: nowrap;
          }
        }
      }

      .configurator-select {
        flex-grow: 1;

        .col {
          padding-right: 5px;
          padding-left: 5px;
        }
      }
    }

    .calculator-tooltip-description {
      text-align: left;

      h1 {
        text-align: center;
      }
    }
  }
}
