.card-action-menu {
  position: relative;
  cursor: pointer;
  display: flex;

  > a,
  a:link,
  a:visited {
    text-decoration: none;
    color: black;
  }

  > .toggle-button {
    height: 30px;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    transition: all 0.2s;

    &:hover,
    &:focus {
      background-color: rgba(100, 100, 100, 0.1);
    }
  }

  > .menu-container {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 30px;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #eee;

    ul {
      margin: 0;
      padding: 0;
    }

    .action {
      font-size: 14px;
      display: block;
      line-height: 22px;
      padding: 14px 10px 14px 10px;
      text-align: left;
      white-space: nowrap;

      .material-icons {
        vertical-align: middle;
        font-size: 20px;
        line-height: 20px;
      }

      &:hover,
      &:focus {
        background-color: rgba(100, 100, 100, 0.1);
      }

      &.disabled {
        background-color: rgba(100, 100, 100, 0.2);
        cursor: not-allowed;
      }
    }

    .separator {
      border-bottom: 1px solid #d3d3d3;
    }
  }

  &.expanded {
    > .menu-container {
      opacity: 1;
      visibility: visible;
    }
  }
}
