.login-bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #dedede;
  width: 100%;
  text-align: center;
}

.login {
  margin-top: 100px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0px;

  label {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .md-form {
    position: relative;
    margin-top: 0;
    margin-bottom: 35px;
  }
  .login-logo-container {
    padding: 20px 0;
    margin-bottom: 35px;
    background: linear-gradient(#045a67, #168ba6);

    .logo {
      margin: auto;
      display: block;
      width: 300px;
    }
  }

  .reset-password-link {
    text-align: right;
    margin-top: -10px;
    padding-bottom: 20px;
  }
}
