.chooser-wrapper {
  position: relative;
  display: inline-block;
}

.edit-icon {
  position: absolute;
  top: 4px;
  right: -30px;
}

.radio-container {
  position: absolute;
  right: -280px;
  top: -45px;
  width: 250px;
  z-index: 10;
  border-radius: 5px !important;

  :global {
    .content {
      border-radius: 5px;

      .radio-group {
        padding: 5px;
      }
    }
  }
}
