@import '../style/variables';

.info-icon-container {
  display: inline-block;
  color: $primary-color;
  font-weight: lighter;
  cursor: help;
  transition: all 0.4s ease;
  opacity: 1;

  &:hover {
    color: $secondary-color-darker;
  }

  &.color-primary {
    color: $primary-color;
  }

  &.color-white {
    color: #fff;

    &:hover {
      color: #fff;
      opacity: 0.6;
    }
  }
  > i.info-warning {
    color: red;
  }
}
