.chip {
  line-height: 5px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  background-color: rgba(99, 99, 99, 0.8);
  box-shadow: 0 0 5px 2px rgba(99, 99, 99, 0.5);
  border-radius: 50%;
}

.active.danger {
  background-color: rgba(217, 58, 48, 0.8);
  box-shadow: 0 0 5px 2px rgba(217, 58, 48, 0.5);
}
.active.warning {
  background-color: rgba(255, 123, 31, 0.8);
  box-shadow: 0 0 5px 2px rgba(255, 123, 31, 0.5);
}

.clickable {
  cursor: pointer;
}
