@import '../../../../style/variables';

.download-chip {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.success {
  background-color: $success-color;
}

.pending {
  background-color: $warning-color;
}

.error {
  background-color: $danger-color;
}
