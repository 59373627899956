%header {
  font-size: 1.2em;
  text-align: center;
  padding-bottom: 10px;
  color: black;
}

$card-lateral-padding: 20px;
$header-lateral-padding: 25px;

.card.custom-card {
  border: 1px solid silver;
  margin: 0 0 1.5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .header {
    @extend %header;
    //border-bottom: 1px solid #cedef8;
    text-align: center;
    padding: 10px $header-lateral-padding;
    background-color: #eeeff0;
    position: relative;
    transition: background-color 0.2s;
    border-top: 4px solid #004799;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid silver;
    font-weight: 500;
    color: #686868;
    min-height: 42px;

    > * {
      z-index: 2;
    }

    .header-content {
      position: absolute;
      padding: 0 10px;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
    }

    .badge {
      position: absolute;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      height: 33px;

      i {
        line-height: 29px;
        font-size: 20px;
        font-weight: bold;
        height: 33px;
      }
    }
  }

  .footer {
    min-height: 45px;

    .footer-content {
      padding-bottom: 10px;
      position: absolute;
      bottom: 0;
      right: $card-lateral-padding;
      height: 45px;

      .btn:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .options-row:not(:last-child) {
    margin-bottom: 40px;
  }

  .bottom-card-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    padding-top: 38px;
    line-height: 20px;
    font-size: 20px;
    background-color: rgba(200, 200, 200, 0.4);
    transition: background-color 0.2s;
    border-top: 1px solid #ddd;

    &:hover {
      background-color: rgba(200, 200, 200, 0.55);
    }
  }

  &.wrapping-content {
    > div {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      > * {
        width: 100%;
      }

      > .content {
        flex: 1;
        padding: 0 $card-lateral-padding 0 $card-lateral-padding;

        .bottom-card-button {
          margin-left: -$card-lateral-padding;
        }
      }
    }
  }

  &.no-padding,
  &.wrapping-content.no-padding {
    .content {
      padding: 0;
    }
  }
  &.internal-top-padding,
  &.wrapping-content.internal-top-padding {
    .content {
      padding-top: 15px;
    }
  }

  &.no-padding,
  &.wrapping-content.no-padding {
    .content {
      padding: 0;
    }
  }

  &.with-bottom-button > div > .content {
    padding-bottom: 100px;
  }
}
