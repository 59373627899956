table.table td {
  &.actions-cell {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    height: 54px;

    > * {
      margin-right: 10px !important;
      display: inline-block;
      width: 24px;
      height: 24px;
      padding: 0;
      box-shadow: none;
      border: none;
      background: transparent;
      float: none;
      top: 0;
      right: 0;
    }
    > *:last-child {
      margin-right: 0 !important;
    }
  }
}
