.editable-text-container {
  cursor: pointer;
  position: relative;
  display: inline-block;

  .edit-icon {
    position: absolute;
    left: -16px;
    top: calc(100% - 20px);
    font-size: 16px;
    line-height: 16px;
    color: #999;
  }

  .content-container {
    &.dotted {
      border-bottom: 1px dotted #999;
    }

    .placeholder {
      color: #999;
    }
  }

  .validate-button {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;

    &.on-input {
      margin-top: 10px;
      right: -24px;
    }
  }

  .copy-button {
    position: absolute;
    display: block;
    cursor: pointer;
    z-index: 1;
    right: -25px;
    top: 3px;

    i {
      font-size: 1em;
    }
  }
}
