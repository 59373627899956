.upload-zone-container {
  height: 100%;
  min-height: 100px;
  position: relative;

  .drag-overlay {
    width: 100%;
    height: 100%;
    background-color: #eee;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s linear;
    visibility: hidden;
    padding: 3px;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px dashed #aaa;
    }
  }
  .drag-overlay.active {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  .files-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 3px;

    .file-item-wrapper {
      display: block;
      width: 150px;
      height: 180px;
      margin: 20px;
      padding: 10px;
      /*background-color: #eee;*/
      border: 3px dashed #ccc;
      position: relative;

      &.uploaded-file {
        /*background-color: #b3e5fc;*/
        border: solid #ccc;

        .title {
          text-align: center;
          font-size: 14px;
        }

        &.no-preview {
          .placeholder {
            cursor: pointer;
            text-align: center;
            position: absolute;
            margin: auto;
            width: 100%;
            left: 0;
            top: 31%; // Trick pour placer l'image de façon centrée

            .inner-placeholder {
              position: relative;

              img {
                max-width: 100px;
                max-height: 100px;
                width: auto;
                height: auto;
              }

              &.default-placeholder {
                img {
                  width: auto;
                  height: 60px;
                }
              }

              .thumbnail-big-container {
                width: 300px;
                height: 400px;

                .content {
                  padding: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .thumbnail-big {
                  max-width: 300px;
                  max-height: 300px;
                  width: auto;
                  height: auto;
                }
              }

              .material-icons {
                display: block;
                color: #fff;
                font-size: 28px;
                position: absolute;
                width: 50px;
                height: 50px;
                top: 26px;
                left: 36px;
              }
            }
          }
        }

        &.refused {
          border-color: #e51c23;
        }

        .btn-reupload {
          position: absolute;
          bottom: 10px;
          left: 0.8rem;

          input[type='file'] {
            width: 0;
            height: 0;
            top: 100px; // Pour ne pas avoir le cas du clic direct sur l'input
            position: absolute;
            outline: none;
            visibility: hidden;
          }
        }
      }

      &.uploading-file {
        .title {
          text-align: center;
          font-size: 14px;
        }

        .placeholder {
          text-align: center;
          position: absolute;
          margin: auto;
          width: 100%;
          left: 0;
          top: 31%; // Trick pour placer l'image de façon centrée
          padding: 0 5px;

          p {
            margin: 0;
          }

          &:last-child {
            text-align: center;
            font-size: 1em;
          }

          .material-icons {
            display: block;
            color: #ccc;
            font-size: 110px;
          }
        }
      }
      .confirmation-button-container {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 10px;
      }

      .flex-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.file-chooser {
        background-color: #eee;
        cursor: pointer;

        .flex-content {
          div {
            text-align: center;

            .material-icons {
              display: block;
              color: #ccc;
              font-size: 64px;
            }
            div {
              color: #333;
              font-size: 18px;
            }

            .sub-title {
              font-size: 12px;
            }
          }
        }

        input[type='file'] {
          width: 0;
          height: 0;
          top: 100px; // Pour ne pas avoir le cas du clic direct sur l'input
          position: absolute;
          outline: none;
          visibility: hidden;
        }
      }
    }
  }
}
