@import '../style/variables';

.user-side-nav {
  height: 620px;
  position: fixed;
  max-width: 25%;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
  background: none;
  box-shadow: none;
  > div:first-child {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .navigation-item {
    color: black;

    .link {
      text-decoration: none;
      color: black;
      display: block;
      width: 100%;
      padding: 10px 15px;
      span {
        margin-left: 10px;
      }
      &:hover {
        background: #dfdfdf;
      }
    }

    .navigation-item-content {
      i {
        vertical-align: bottom;
      }
    }

    .active-link {
      .link {
        background-color: $secondary-color-darker;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .user-side-nav {
    padding-right: 8px;
    .navigation-item {
      .link {
        font-size: 13px;
      }
    }
  }
}
