@import '../../../style/variables';

.reseller-app {
  .order-details-page {
    margin-top: 90px;

    &.breadcrumb-padded {
      margin-top: 150px;
    }

    .go-back-link {
      color: white;
      position: absolute;
      left: 20px;
      font-size: 16px;
      top: 16px;

      i {
        vertical-align: middle;
      }
    }

    .card {
      &.order-details-infos {
        line-height: 30px;

        .content {
          padding-bottom: 20px !important;

          .row:first-child {
            margin-top: 20px;
          }

          .order-detail-link {
            margin-top: 10px;
          }
        }

        &.quote-details-infos {
          .order-detail-link {
            margin-bottom: 75px;
          }
        }
      }
    }

    .cart-height {
      margin-bottom: 50px;

      .cart-container {
        table {
          .cart-content-header-statut,
          .cart-item-statut {
            width: 30%;
          }
        }
      }
    }

    .in-line-edit-offset {
      left: -12px;
    }

    .editable-text-container {
      .input-error {
        white-space: nowrap;
        color: $danger-color-dark;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .order-details-page {
      &.breadcrumb-padded {
        margin-top: 130px;
      }
    }
  }
}
