@font-face {
  font-family: 'pc-icon-library';
  src: url('/fonts/pc-icon-library/pc-icon-library.eot?957d7a5e05612f8b03b29cfdeabf9ae5?#iefix')
      format('embedded-opentype'),
    url('/fonts/pc-icon-library/pc-icon-library.woff2?957d7a5e05612f8b03b29cfdeabf9ae5')
      format('woff2'),
    url('/fonts/pc-icon-library/pc-icon-library.woff?957d7a5e05612f8b03b29cfdeabf9ae5')
      format('woff'),
    url('/fonts/pc-icon-library/pc-icon-library.ttf?957d7a5e05612f8b03b29cfdeabf9ae5')
      format('truetype'),
    url('/fonts/pc-icon-library/pc-icon-library.svg?957d7a5e05612f8b03b29cfdeabf9ae5#pc-icon-library')
      format('svg');
}

i {
  line-height: 1;
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: pc-icon-library !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-production_mode_standard:before {
  content: '\f104';
}

.icon-production_mode_express:before {
  content: '\f102';
}

.icon-production_mode_overnight:before {
  content: ' '; // TODO ajouter l'icône
}

.icon-shipping_mode_transporter:before {
  content: '\f104'; // TODO ajouter l'icône
}

.icon-shipping_mode_courier:before {
  content: '\f101';
}

.icon-shipping_mode_pickup:before {
  content: '\f103';
}

.icon-order_item_status_confirmed:before {
  content: '\f105';
}

.icon-order_item_status_delivered:before {
  content: '\f106';
}

.icon-order_item_status_files_refused:before {
  content: '\f107';
}

.icon-order_item_status_files_sent:before {
  content: '\f108';
}

.icon-order_item_status_files_validated:before {
  content: '\f109';
}

.icon-order_item_status_laminated:before {
  content: '\f10a';
}

.icon-order_item_status_packaged:before {
  content: '\f10b';
}

.icon-order_item_status_pending_print:before {
  content: '\f10c';
}

.icon-order_item_status_printed:before {
  content: '\f10d';
}

.icon-order_item_status_shipped:before {
  content: '\f10e';
}

.icon-order_item_status_validated_for_production:before {
  content: '\f10f';
}
