@import './style/variables';

html,
body,
.App,
#root {
  height: 100%;
}

body {
  font-size: 15px;
  font-weight: 400;
}

.App {
  height: 100%;
  width: 100%;
  // Décalage de la height de la navbar
  padding-top: 54px;

  .loader {
    text-align: center;
    margin: 40vh auto auto;
    font-size: 7vh;
    color: $secondary-color;
    border-top: 11px solid $secondary-color;
  }

  .reseller-app-page-title {
    background-color: $primary-color-darker;
    text-align: center;
    color: white;
    font-size: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    position: fixed;
    top: 54px;
    width: 100%;
    z-index: 1000;
  }

  .dropdown-menu a {
    padding: 0;
    font-size: 0.9rem;
    width: 100%;
    display: block;
  }

  .dropdown-menu {
    .dropdown-item:hover {
      a {
        color: #ffffff;
      }
    }
  }

  hr {
    margin: 5px 0;
  }
}

.background {
  &::after {
    content: '';
    background-image: url('./style/background.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    opacity: 1;
    transition: opacity 0.7s;
  }

  &.loading-initial-data {
    &::after {
      opacity: 0;
    }
  }
}

table {
  td {
    font-weight: 400;
  }
}

.bg-secondary-darker {
  background-color: $secondary-color-darker !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-primary-lighter {
  background-color: $primary-color-lighter !important;
}
.bg-primary {
  background-color: $primary-color !important;
}
.bg-primary-darker {
  background-color: $primary-color-darker !important;
}

.invalid-feedback {
  position: relative;
  top: -15px;
}

.alert {
  border-radius: 0;
  i {
    font-size: 15px;
    font-weight: bold;
  }
  &.alert-warning {
    color: #212529 !important;
    border-color: #da9916;
    padding: 5px;
    font-size: 13px;
    margin: 0;

    &.global-calculate-message {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}
.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-default .dropdown-item.active,
.dropup .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-default .dropdown-item:active,
.dropup .dropdown-menu.dropdown-default .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-default .dropdown-item.active,
.dropright .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-default .dropdown-item:active,
.dropright .dropdown-menu.dropdown-default .dropdown-item.active {
  background-color: $primary-color !important;
}

.badge {
  font-size: 100%;
}

@media screen and (max-width: 1600px) {
  .alert {
    &.alert-warning {
      padding: 10px;
      font-size: 13px;
    }
  }
  .laptop-padding-right {
    padding-right: 8px;
  }
  .laptop-padding-left {
    padding-left: 8px;
  }
}
