@import '../../../../style/variables';

.btn-reupload {
  input[type='file'] {
    width: 0;
    height: 0;
    top: 100px;
    position: absolute;
    outline: none;
    visibility: hidden;
  }
}
