@import '../style/variables';

.spinner-wrapper {
  text-align: center;

  &.page-loader {
    margin: 40vh auto auto;
  }

  .preloader-wrapper.big {
    width: 8rem;
    height: 8rem;
  }

  .spinner-layer {
    border-color: $secondary-color;
  }

  .preloader-wrapper .spinner-layer .circle-clipper .circle {
    border-width: 0.3rem;
  }
}
