.title {
  padding-bottom: 10px;
}

.explanation {
  padding-bottom: 20px;
}

.success {
  margin-bottom: 35px;
}
