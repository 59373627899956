@import '../style/variables';

.card-action-footer {
  background-color: rgba(218, 218, 218, 0.5);
  width: 100%;
  padding: 12px;

  .actions-container {
    float: right;

    > *:not(:first-child) {
      margin-left: 0.2rem;
    }

    :global {
      .hover-surface-container {
        display: inline-block;
      }
    }
  }

  &.fixed-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .card-action-footer {
    padding: 12px 4px;
  }
}
