.cost-card-form {
  .content {
    font-size: 16px !important;
    line-height: 30px;
    padding-bottom: 15px !important;
  }
  .delivery-alert {
    margin-top: 35px;
  }
}
