.confirmation-button-container {
  &.default {
    text-align: center;

    .inner-confirmation-button-container {
      width: 140px; // bouton central : 40px + boutons côté : 45px x 2 + padding : 5px x 2
      position: relative;
      margin: auto;

      .btn-flat {
        text-align: center;
        width: 45px;
        height: 100%;
        transition: width 0.2s linear, left 0.2s linear, right 0.2s linear;
        position: absolute;

        &.option-button__no {
          padding: 0 5px 0 0;
          color: #d32f2f;
          left: 5px;

          &.option-button--hidden {
            left: 45px;
          }
        }

        &.option-button__yes {
          padding: 0 0 0 5px;
          color: #388e3c;
          right: 5px;

          &.option-button--hidden {
            right: 90px;
          }
        }

        &.option-button--hidden {
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
    }
  }
}
