@import '../../../../style/variables';
.cart-container {
  .cart-nb-products {
    margin-right: -20px;
    margin-left: -20px;
    padding: 3px 0;
    background: #9e9e9e;
    text-align: center;
    color: #ffffff;
  }

  table {
    text-align: center;
    thead.thead-light {
      background: $primary-color-lighter;
    }

    .cart-content-header-actions,
    .cart-item-actions {
      display: flex;
      width: 100%;
    }
    .cart-content-header-label,
    .cart-item-label {
      width: 35%;
      .hovered-content {
        left: -50px;
      }
    }
    .cart-content-header-quantity,
    .cart-item-quantity {
      width: 15%;
    }
    .cart-content-header-price,
    .cart-item-price {
      width: 20%;
    }
  }

  .empty-cart-message {
    grid-column: 1 / -1;
    text-align: center;
    padding: 50px 0;
    color: grey;

    i {
      font-size: 4em;
    }
  }

  .editable-shipping-cost {
    float: right;
  }

  .refresh-shipping-cost {
    margin-right: 5px;
  }

  .sub-header,
  .recap-line {
    margin: 0 0;
  }

  .delivery-types-container,
  .recap-line-container {
    font-size: 16px;
    line-height: 35px;
  }

  .recap-line-container {
    padding: 0.5rem 0;
  }

  .delivery-types-container {
    padding: 0.5rem 1rem;

    label {
      margin: 0;
    }
  }

  .address-display-container {
    padding: 0 1rem;

    address {
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  .cart-item-actions,
  .file-info-container {
    .order-item-info-chip,
    .inline-icon-button {
      display: inline-block;
      width: 24px;
      height: 24px;
      padding: 0;
      box-shadow: none;
      border: none;
      background: transparent;
      float: none;
      top: 0;
      right: 0;
    }

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .hovered-content {
    left: -200px;
  }
}

@media screen and (max-width: 1600px) {
  .cart-container {
    .order-item-info-chip,
    .inline-icon-button {
      margin-right: 3px !important;
    }
    table {
      .cart-item-quantity,
      .cart-content-header-quantity {
        width: 10% !important;
      }
      .cart-item-price,
      .cart-content-header-price {
        width: 24% !important;
      }
      .cart-content-header-label,
      .cart-item-label {
        width: 30%;
      }
    }
    .delivery-types-container,
    .recap-line-container {
      line-height: 30px;
    }
    .delivery-types-container,
    .recap-line-container {
      padding: 10px;
    }
    .recap-line {
      .col-md-6 {
        padding: 0;
      }
    }
    .cart-recap-price-label,
    .cart-recap-price-amount {
      padding: 0 10px;
    }
  }
}
