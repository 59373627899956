.transporter-detail-container {
  background-color: #e5e5e5;

  .transporter-name {
    background-color: #dfdfdf;
    font-weight: bold;
    font-size: 16px;
  }

  .item-container {
    padding: 10px 0;
    .item {
      background-color: #e6e6e6;
      font-size: 16px;
      .tracking-number {
        line-height: 25px;
      }
    }
  }
}
