.copiable-text-container {
  position: relative;
  display: inline-flex;
  align-items: center;

  .copy-button {
    display: block;
    margin-left: 5px;
    padding: 2px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    line-height: 14px;
    cursor: pointer;
    user-select: none;
    z-index: 1;

    i {
      font-size: 14px;
    }
  }

  .hidden-copy-content {
    display: inline-block;
    position: absolute;
    left: -10000px;
    top: -10000px;
  }
}
