@import '../../../style/variables';

.shipping-address-form-container {
  .address-chooser {
    padding: 4px 15px;
    background-color: $primary-color-lighter;

    .select-container {
      display: flex;
      align-items: center;

      > div {
        flex-grow: 2;
      }

      span {
        color: #fff;
        border-bottom: 1px solid #fff;
        box-shadow: none;

        input {
          color: #fff;

          &::placeholder {
            color: #fff;
            text-transform: uppercase;
          }
        }
        i {
          color: #fff;
        }
      }
    }
  }

  .address-form-container {
    padding: 1.5rem 15px;
  }
}
