.order-item-detail-container {
  min-height: 200px;
  width: 300px;
  display: flex;
  align-items: flex-start;

  &.with-timeline {
    width: 600px;
  }

  .manufacture-info-panel {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;

    .title {
      text-align: center;
      font-size: 20px;
      padding-bottom: 10px;
      border-bottom: solid 1px #c3c3c3;
      margin-bottom: 10px;
    }

    &.manufacture-info-panel {
      width: 300px;
      min-height: 200px;
      .editable-text-container .edit-icon {
        left: 100px;
        top: 0;
      }

      textarea {
        width: 100%;
      }
    }

    .order-item-timeline {
      width: 300px;
      min-height: 200px;
      margin-left: 5px;
    }
  }
}
