.page {
  margin-top: 90px;
}

.card-content {
  padding: 20px 50px;

  > p {
    width: 70%;
    margin: auto auto 20px auto;
  }
}

.download-section {
  display: flex;
  width: 100%;
  min-height: 50px;
  justify-content: center;
}

.actions-container {
  display: flex;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
}
