.cart-totals-and-delivery {
  margin-bottom: 85px;

  .half-card {
    height: 100%;
  }

  .shipping-modes-form {
    .radio-group {
      display: flex;
      justify-content: space-around;
    }
  }

  .address-display-container {
    padding-top: 0.5rem;
  }
}
