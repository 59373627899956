@import '../style/variables';

.navbar {
  padding: 0.1rem 1rem;

  .mascarade-label {
    background-color: #fbda3b;
    padding: 5px;
    margin-right: 90px;
    font-size: 20px;
  }

  .user-icon-link {
    background-color: $primary-color-lighter;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    .nav-link {
      text-transform: uppercase;
      font-weight: 400;
      padding: 13px 10px !important;

      i {
        font-size: 30px;
        margin-left: 3px;
        vertical-align: bottom;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-item {
      padding: 0 !important;

      .link-with-badge {
        .badge {
          margin-left: 7px;
        }
      }

      a,
      a:visited {
        font-weight: 300;
        color: #000;
        padding: 0.5rem;
        transition: color 0s;

        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }

      &.logout-button {
        cursor: pointer;
        padding: 0.5rem !important;
      }
    }
  }
}
