@import '../../../../style/variables';

.order-details-page {
  .item-badge {
    font-size: 15px;
    padding-right: 10px;
    padding-left: 10px;
    position: relative;
    top: -7px;
    left: 0;

    &.recap-item-badge {
      background-color: $primary-color;
      color: #fff;
      cursor: help;
      &:hover {
        background-color: $secondary-color-darker;
      }
    }
  }
}

.quote-container.file-upload-container .cart-content table td.file-info-container {
  width: 110px !important;
}

.file-upload-container {
  .file-info-container {
    white-space: nowrap;
    display: flex;
    align-items: center;

    > * {
      margin-right: 8px !important;
      height: 24px;
    }

    .file-icon-navigate {
      i {
        color: $primary-color;
        cursor: pointer;
        &:hover {
          color: $secondary-color-darker;
        }
      }

      &:focus {
        i {
          color: $secondary-color-darker;
        }
      }
    }
  }
}

.cart-item-line {
  .inline-confirmation-button-container.visible-options {
    position: absolute;
    top: 14px;
  }
}
