.quote-container {
  &.file-upload-container {
    .upload-files-container {
      margin-bottom: 50px !important;
    }
    .cart-container .cart-height {
      min-height: auto;
    }
    .cart-content {
      table {
        margin-bottom: 400px;

        th,
        td {
          width: auto !important;
        }

        .table-info .cart-item-actions {
          width: auto !important;
        }
      }
      .fixed-height-265px {
        height: 285px;
      }
      .with-delivery-tracking {
        margin-bottom: 95px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .quote-container {
    &.file-upload-container {
      .cart-content {
        table {
          margin-bottom: 420px;
        }
        .fixed-height-265px {
          height: 305px;
        }
      }
    }
  }
}
