@import '../style/variables';

.breadcrumb-container {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  position: fixed;
  top: 110px;
  width: 100%;
  z-index: 1000;

  > div {
    width: 80%;
    margin: auto;
    display: flex;
  }

  .step {
    width: 25%;
    display: inline-block;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    &:last-child {
      flex: 0;
    }
  }

  .step-elements,
  .step-elements:hover,
  .step-elements:visited {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0 10px;

    > * {
      display: inline-block;
    }

    &:hover:not(.inactive) {
      .text {
        opacity: 0.6;
      }
    }

    &.inactive {
      user-select: none;
    }
  }

  .round {
    background-color: $primary-color;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: relative;
    margin-right: 10px;
  }

  .round.selected {
    background-color: $secondary-color;
  }

  .round.inactive {
    background-color: #9d9d9d;
  }

  .text {
    font-size: 17px;
    line-height: 35px;
    font-weight: 400;
    flex-grow: 1;
  }

  .text.inactive {
    color: #9d9d9d;
  }

  .ligne {
    border-bottom: 1px solid #9d9d9d;
    padding-top: 17px;
    flex-grow: 20;
  }
}
