@import '../../../../style/variables';

$title-and-buttons-height: 24px;
$line-height: 31px + $title-and-buttons-height;

.file-line {
  display: flex;
  justify-content: flex-start;
  height: $line-height;
  width: 100%;
  padding: 5px;
  background-color: #eaeaea;
  border: 2px solid #dedede;
  margin: 0 0 5px;
}

.error-line {
  background-color: $danger-color-bg;
  border-color: $danger-color;
}

.icon {
  width: 40px;
  margin: auto 5px auto auto;

  :global {
    .hovered-content {
      z-index: 1000;
    }
  }
}

.icon-img {
  width: auto;
  height: auto;
  max-width: 40px;
  max-height: 40px;
}

.title-and-bar {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title-and-buttons {
  flex: 1;
  display: flex;
  height: $title-and-buttons-height;
}

.bar {
  margin-top: 10px;
  background-color: #9d9d9d;
  height: 3px;
}

.bar-error {
  margin-top: 0;
  background: transparent;
  margin-top: -4px;
  height: auto;
}

.neutral-message {
  color: #9d9c9e;
  white-space: nowrap;
}

.error-message {
  color: $danger-color;
  font-size: 14px;
}

.title {
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
}

.buttons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
}

.status-chip {
  margin-right: 5px;
}

.file-name {
  margin-right: 10px;
  white-space: nowrap;
}
.file-size {
  margin-right: 60px;
}
